.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.topic{
  font-size: 46px !important;
}
.topic-small{
  font-size: 36px !important;
}
.sub-topic{
  font-size: 28px !important;
}
.sub-topic-small{
  font-size: 20px !important;
}
/* .topic{} */
.weight-light{
  font-weight: 300 !important;
}
.weight-regular{
  font-weight: 400 !important;
}
.weight-medium{
  font-weight: 500 !important;
}
.weight-bold{
  font-weight: 700 !important;
}
.font-10{
  font-size: 10px;
}
.font-12{
  font-size: 12px;
}
.font-14{
  font-size: 14px;
}
.font-18{
  font-size: 18px;
}
.font-20{
  font-size: 20px;
}
.font-22{
  font-size: 22px;
}
.font-24{
  font-size: 24px;
}
.font-26{
  font-size: 26px;
}
.font-28{
  font-size: 28px;
}
.font-30{
  font-size: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
